<template>
    <div class="view pa24 researchCosts">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span>
            <i class="el-icon-caret-bottom  fs12 ml5"></i>
        </div>
        <el-tabs v-model="searchData.year" @tab-click="searchFun">
            <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                :key="index"></el-tab-pane>
        </el-tabs>
        <el-tabs v-model="searchData.dataType" @tab-click="switchType" v-if="typeObj['汇总']" class="tabsWidthAuto">
            <el-tab-pane :label="item.title" :name="String(id)" v-for="(item, id) in typeObj" :key="id"></el-tab-pane>
        </el-tabs>
        <div class="d-flex mt20 mb20">
            <div style="margin-left: auto;">
                <el-button-group>
                    <el-button type="primary"
                        :disabled="!checkedCompany.pcId || !searchData.dataType || searchData.dataType == '汇总'"
                        :loading="exportLoadingBtn" @click="exportTable()">导出全部归集表</el-button>
                    <el-button type="primary"
                        :disabled="!checkedCompany.pcId || !searchData.dataType || searchData.dataType == '汇总'"
                        :loading="exportLoadingBtn"
                        @click="exportTable(typeObj[searchData.dataType].pdId)">单表导出</el-button>
                </el-button-group>
            </div>
        </div>
        <div class="oyauto">
            <table border="1" cellspacing="0" class="tableBorder assistrecordTable" v-loading="loading">
                <thead>
                    <tr class="h40 baf7fafa">
                        <td class="textc itemTitle">
                            序号
                        </td>
                        <td class="textc itemTitle pl10 pr10" colspan="2">
                            项目
                        </td>
                        <td class="textc itemTitle">
                            发生额
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="h40" v-for="(row, index) in tableTitle" :key="index">
                        <td class="textc itemContent">{{ row.code }}</td>
                        <td class="itemContent pl10 pr10" v-if="row.title" :class="{ 'fw600': row.weight }"
                            :colspan="row.colspan" :rowspan="row.rowspan">{{
            row.title }}</td>
                        <td class="itemContent pl10 pr10 mw150" v-if="row.childTitle">{{ row.childTitle }}</td>
                        <td class="textc itemContent mw150">{{ moneyData[row.code] | setMoney }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { getProjectMoneyDeduction } from "@/api/projectMoney";
export default {
    name: "researchCosts",
    components: {
        selectCompanyList,
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            showModel: false,
            yearData: [],
            moneyData: [],
            tableTitle: [
                { title: "一、人员人工费用小计", code: 1, weight: true, colspan: 2 },
                {
                    title: "直接从事研发活动人员",
                    code: 1.1,
                    colspan: 1,
                    rowspan: 2,
                    childTitle: "工资薪金",
                },
                {
                    title: "",
                    code: 1.2,
                    colspan: 1,
                    childTitle: "五险一金"
                },
                { title: "外聘研发人员的劳务费用", code: 1.3, colspan: 2 },
                { title: "二、直接投入费用小计", code: 2, weight: true, colspan: 2 },
                {
                    code: 2.1,
                    title: "研发活动直接消耗",
                    colspan: 1,
                    rowspan: 3,
                    childTitle: "材料"
                },
                {
                    code: 2.2,
                    title: "",
                    childTitle: "燃料"
                },
                {
                    code: 2.3,
                    title: "",
                    childTitle: "动力费用"
                },
                { title: "用于中间试验和产品试制的模具、工艺装备开发及制造费", code: 2.4, colspan: 2 },
                { title: "用于不构成固定资产的样品、样机及一般测试手段购置费", code: 2.5, colspan: 2 },
                { title: "用于试制产品的检验费", code: 2.6, colspan: 2 },
                { title: "用于研发活动的仪器、设备的运行维护、调整、检验、维修等费用", code: 2.7, colspan: 2 },
                { title: "通过经营租赁方式租入的用于研发活动的仪器、设备租赁费", code: 2.8, colspan: 2 },
                { title: "三、折旧费用小计", code: 3, weight: true, colspan: 2 },
                { title: "用于研发活动的仪器的折旧费", code: 3.1, colspan: 2 },
                { title: "用于研发活动的设备的折旧费", code: 3.2, colspan: 2 },
                { title: "四、无形资产摊销小计", code: 4, weight: true, colspan: 2 },
                { title: "用于研发活动的软件的摊销费用", code: 4.1, colspan: 2 },
                { title: "用于研发活动的专利权的摊销费用", code: 4.2, colspan: 2 },
                { title: "用于研发活动的非专利技术（包括许可证、专有技术、设计和计算方法等）的摊销费用", code: 4.3, colspan: 2 },
                { title: "五、新产品设计费等小计", code: 5, weight: true, colspan: 2 },
                { title: "新产品设计费", code: 5.1, colspan: 2 },
                { title: "新工艺规程制定费", code: 5.2, colspan: 2 },
                { title: "新药研制的临床试验费", code: 5.3, colspan: 2 },
                { title: "勘探开发技术的现场试验费", code: 5.4, colspan: 2 },
                { title: "六、其他相关费用小计", code: 6, weight: true, colspan: 2 },
                { title: "差旅费", code: 6.1, colspan: 2 },
                { title: "办公用品", code: 6.2, colspan: 2 },
                { title: "会议费", code: 6.3, colspan: 2 },
                { title: "知识产权", code: 6.4, colspan: 2 },
                { title: "技术服务费", code: 6.5, colspan: 2 },
                { title: "其他", code: 6.6, colspan: 2 },
                { title: "七、委托外部机构或个人进行研发活动所发生的费用", code: 7, weight: true, colspan: 2 },
                { title: "其中：委托境外进行研发活动所发生的费用（包括存在关联关系的委托研发）", code: 7.1, colspan: 2 },
                { title: "八、允许加计扣除的研发费用中的第1至5类费用合计（1+2+3+4+5）", code: 8, weight: true, colspan: 2 },
                { title: "其他相关费用限额=序号8×10％/(1-10％)", code: 8.1, colspan: 2 },
                { title: "九、当期费用化支出可加计扣除总额", code: 9, weight: true, colspan: 2 },
                { title: "十、研发项目形成无形资产当期摊销额", code: 10, weight: true, colspan: 2 },
                { title: "其中：准予加计扣除的摊销额", code: 10.1, colspan: 2 },
                { title: "十一、当期实际加计扣除总额（9+10.1）×100％", code: 11, weight: true, colspan: 2 },

            ],
            searchData: { name: "", dataType: "", year: "" },
            typeObj: {},
            checkedCompany: {},
            //模态框配置
            modelTitle: "",
            formData: {
                puId: "",
                dataType: "",//数据类型（1：工资计算，2：社保，3：公积金，4：劳务费）
                year: "",//年份
                month: "",//月份
                remark: "",//摘要
                researchMoney: "",//	计入研发费金额
                subject: "",//科目
                voucherCode: "",//凭证号
                voucherMoney: "",//凭证金额
                wages: "",//应发工资总额
            },
            rules: {
                puId: {
                    required: true,
                    trigger: "change",
                    message: "请输入企业人员",
                },
            },
            formType: "",
            updateId: 0,
            loadingBtn: false,
            exportLoadingBtn: false,
        };
    },
    filters: {
        setMoney(money) {
            return money ? (money).toFixed(2) : 0;
        }
    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        //获取年份
        this.getYearData();
        this.getList();
    },
    methods: {
        exportTable(pdId) {
            this.exportLoadingBtn = true;
            let params = {
                pcId: this.checkedCompany.pcId,
                year: this.searchData.year,
                isOutPut: true
            };
            if (pdId) {
                params.pdId = pdId;
            }
            getProjectMoneyDeduction(params).then(res => {
                this.exportLoadingBtn = false;
                if (res.data) {
                    this.$message.success("导出归集表中...");
                    let link = document.createElement('a');
                    link.href = '/api/crmPc/project/downloadWordFile?fileName=' + res.data;
                    link.click();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.exportLoadingBtn = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchFun();
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData=[];
let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
this. yearData= yearData;
            this.searchData.year = String(this.yearData[0])
        },
        /**@method 搜索 */
        searchFun() {
            this.getList();
        },
        switchType() {
            for (let money of this.typeObj[this.searchData.dataType].countDataList) {
                this.$set(this.moneyData, money.code, money.money);
            }
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                year: this.searchData.year,
            };
            if (!this.checkedCompany.pcId) {
                this.moneyData = {};
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;
                let result = await getProjectMoneyDeduction(params);
                this.loading = false;
                const { data } = result;
                this.typeObj = {};
                for (let row of data) {
                    if (row.code != '汇总') {
                        row.title = row.code + row.name.slice(0, 5) + "...";
                    } else {
                        row.title = '汇总表'
                    }
                    this.typeObj[row.code] = row;
                }
                this.searchData.dataType = data[0].code;
                for (let money of this.typeObj[data[0].code].countDataList) {
                    this.moneyData[money.code] = money.money;
                }
            } catch (error) {
               //-console.log(error);
                this.loading = false;
                this.moneyData = {};
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.assistrecordTable {
    border-color: #000;
    word-break: break-all;
    // table-layout: fixed;

    thead tr,
    tbody tr,
    tfoot tr {
        // display: table;
        width: 100%;

        // table-layout: fixed;
        td {
            box-sizing: border-box;
        }
    }

    tbody {
        // display: block;
    }

    .itemTitle {
        font-size: 16px;
        padding: 5px 10px;
    }

    .itemContent {
        min-height: 30px;
        font-size: 16px;
        padding: 5px 10px;
    }

}
</style>